import React from "react"
import { graphql, Link } from "gatsby"
import { Col, Container, Row } from "react-grid-system"
import { Box } from "@chakra-ui/core"

import {
  Hero,
  IconText,
  Button,
  Quote,
  ImageQuote,
  Intro,
  Person,
  SEO,
} from "../components"

const HomePage = ({ data: { homepage } }) => {
  const seo = homepage.nodes[0].seo
  const image = homepage.nodes[1].hero_.image.childImageSharp.fluid
  const iconText = homepage.nodes[2].iconText
  const persons = homepage.nodes[3].person

  if (!homepage) return null
  return (
    <>
      <SEO seo={seo} />
      <Hero
        image={image}
        fluid
        alt="Hero Bild"
        text="Wer Veränderungen umarmen will, muss das Neue erstmal begrüßen."
      />
      <Intro />
      <Box as={Container}>
        {iconText.map((item, i) => {
          return <IconText input={item} key={i} />
        })}

        <Row>
          <Col md={11}>
            <Box mt="20" textAlign={{ base: "center", md: "right" }} w="100%">
              <Button
                as={Link}
                to="/angebot"
                fontSize={{ base: "18px", lg: "2xl" }}
              >
                Unser Angebot
              </Button>
            </Box>
          </Col>
        </Row>
      </Box>
      {persons.map((item, i) => {
        return <Person input={item} key={i} />
      })}
      <Quote />
      <ImageQuote />
    </>
  )
}

export default HomePage

export const query = graphql`
  {
    homepage: allHomepageJson {
      nodes {
        seo {
          description
          title
        }
        hero_ {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        person {
          headline
          subline
          text
          reverse
          image {
            publicURL
          }
          alt
          social {
            linkedin {
              link
              icon {
                publicURL
              }
            }
            xing {
              link
              icon {
                publicURL
              }
            }
          }
        }
        iconText {
          headline
          icon {
            publicURL
          }
          text
          alt
        }
      }
    }
  }
`
